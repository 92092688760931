
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState, PropsWithChildren } from "react";
import { WagmiProvider as RootWagmiProvider, http, createConfig } from "wagmi";
import { sepolia } from "wagmi/chains";
import { injected } from "wagmi/connectors";

interface WagmiProviderProps extends PropsWithChildren {}

const config = createConfig({
  chains: [sepolia],
  connectors: [injected()],
  ssr: true,
  transports: {
    [sepolia.id]: http(),
  },
});

/**
 * wagmi wrapper for the application.
 */
const WagmiProvider = (props: WagmiProviderProps) => {
  const { children } = props;
  const [queryClient] = useState(() => new QueryClient());
  return (
    <RootWagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </RootWagmiProvider>
  );
};

export default WagmiProvider;