import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { ThirdwebProvider } from "thirdweb/react";
//@ts-ignore
import { ChakraProvider } from "@chakra-ui/react";
import WagmiProvider from "./service/wagmi_server";
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Provider store={store}>
        <WagmiProvider>
          <ThirdwebProvider>
            <App />
          </ThirdwebProvider>
        </WagmiProvider>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
