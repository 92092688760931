import URLS from "../utils/api-end-points";
import { getAccessToken, getUserId } from "../utils/common";

const getHeaders = (baseUrl = true, isForm = false) => {
  const headers = {};
  const apiKey = "auth_key";
  headers[apiKey] = process.env.REACT_APP_API_KEY;
  const contentType = "Content-Type";
  if (!isForm) {
    headers[contentType] = baseUrl ? "application/text" : "application/json";
  }
  return headers;
};

const post = async (url, data = {}, baseUrl = true) => {
  let response = {};
  const accessToken = getAccessToken();
  const userId = getUserId();
  if (accessToken !== null);
  if (baseUrl) {
    if (data instanceof FormData) {
      data.append("user_id", userId);
      data.append("access_token", accessToken);
    } else {
      data = JSON.stringify(
        Object.assign(data, {
          user_id: parseInt(userId, 10),
          access_token: accessToken,
        })
      );
    }
  } else {
    data = JSON.stringify(
      Object.assign(data, {
        user_id: parseInt(userId, 10),
        access_token: accessToken,
      })
    );
  }
  console.log("Data", baseUrl, data);
  const URL = `${baseUrl ? URLS.BASE_URL : URLS.RELAYER_BASE_URL}${url}`;
  await fetch(`${URL}`, {
    method: "POST",
    body: data,
    headers: getHeaders(baseUrl, data instanceof FormData),
  })
    .then(async (result) => {
      unAuthorizeAccess(result);
      response = await result.json();
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};

const get = async (url) => {
  let response = {};
  const URL = `${URLS.BASE_URL}${url}`;
  await fetch(`${URL}`, {
    method: "GET",
  })
    .then(async (result) => {
      unAuthorizeAccess(result);
      response = await result.json();
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};
const unAuthorizeAccess = (res) => {
  if (res.status == 440) {
    localStorage.clear();
    window.location.href = "/login";
  }
};

export default { get, post };
