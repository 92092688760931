// @ts-nocheck
import { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { Link, useNavigate } from "react-router-dom";
import HeaderStyle from "./UserHeader.module.css";
import FooterStyle from "../Footer.module.css";
import ButtonStyle from "../Button.module.css";
import MobileMenuIconBlack from "../../../Assets/Images/MobileMenuIconBlack.svg";
import MobileMenuIconWhite from "../../../Assets/Images/mobileMenuWhite.svg";
import MenuWhite from "../../../Assets/Images/menuWhite.svg";
import MenuYellow from "../../../Assets/Images/menuYellow.svg";
import whiteCross from "../../../Assets/Images/whiteCross.svg";
import BlackX from "../../../Assets/Images/BlackX.svg";
import CloseOrange from "../../../Assets/Images/CloseOrange.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearLocalStorage } from "../../../utils/common";
import { setUserLogout } from "../../../redux/actions/user-action";
import HeaderIcon from "../../../Assets/Images/goldefi/goldefiLogo.svg";
import MobileHeaderIcon from "../../../Assets/Images/goldefi/mobileGoldefiLogo.svg";
import GoldefiLogo from "../../../Assets/Images/goldefi/goldefi-logo.svg";
import profileIconWhite from "../../../Assets/Images/profileIconWhite.svg";
import profileIconBlack from "../../../Assets/Images/profileIconBlack.svg";
import DashBoardIcon from "../../../Assets/Images/dashboardIcon.svg";
import DashBoardIconSelected from "../../../Assets/Images/goldefi/dashboardIconSelected.svg";
import MyTokenIcon from "../../../Assets/Images/myTokenIcon.svg";
import MyTokenIconSelected from "../../../Assets/Images/goldefi/myTokensIconSelected.svg";
import BuyTokenIcon from "../../../Assets/Images/buyTokenIcon.svg";
import BuyTokenIconSelected from "../../../Assets/Images/goldefi/buyTokenIconSelected.svg";
import RedeemTokenIcon from "../../../Assets/Images/redeemTokenIcon.svg";
import RedeemTokenIconSelected from "../../../Assets/Images/goldefi/redeemIconSelected.svg";
import SearchDistributor from "../../../Assets/Images/goldefi/searchDistributor.svg";
import SelectedSearchDistributor from "../../../Assets/Images/goldefi/selectedSearchDistributor.svg";
import { useDisconnect as wagmiDisconnect } from "wagmi";
// import { useActiveWallet, useDisconnect as thirdwebDisconnect } from "thirdweb/react";
// import SupportIcon2 from "../../../Assets/Images/support-icon.svg";
import SupportIcon from "../../../Assets/Images/supportIcon.svg";
import { ImProfile } from "react-icons/im";
import logoutBlack from "../../../Assets/Images/logoutBlack.svg";
import { GrLogout } from "react-icons/gr";
import Dropdown from "react-bootstrap/Dropdown";
import "./dropDown.css";
import CommonHeaderIcon from "./icons/CommonHeaderIcon";
import notify from "../../../utils/notify";

const Header = ({ title }: any) => {
  const [overLayOpen, setOverLayOpen] = useState<boolean>(false);

  const [hover, sethover] = useState("");
  const localUserName = localStorage.getItem("user_name");
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [forPage, setForPage] = useState("");
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  const { isLoggedIn, userDetails, kycStatus } = useSelector(
    (state: any) => state.userReducer
  );


  const { disconnect: disconnectWagmi } = wagmiDisconnect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNav = (nav: string) => {
    navigate(nav);
  };
  const handleLogout = () => {
    clearLocalStorage();
    disconnectWagmi();
    dispatch(setUserLogout());
    navigate("/login");
  };
  const toggleMobileMenu = () => {
    setOverLayOpen(!overLayOpen);
  };
  const closeOverlay = () => {
    setOverLayOpen(!overLayOpen);
  };
  const handleNavigate = (path: string) => {
    // navigate(path);
    if (kycStatus === "Verified") {
      navigate(path);
    } else {
      notify.error("You must complete your KYC first.");
      navigate("/my-profile");
    }
  };

  useEffect(() => {
    if (screenSize !== 0) {
      screenSize > 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);

  useEffect(() => {
    if (title === "Purchase" || title === "Purchase Token") setForPage("black");
    else if (title === "My Orders" || title === "Dashboard")
      setForPage("green");
    else setForPage("white");
  }, [title]);
  return (
    <>
      <div
        className={HeaderStyle.OthersMobileMenuOverlay}
        // className={HeaderStyle.MobileMenuOverlay}
        style={{ width: `${(overLayOpen && "100%") || "0px"}` }}>
        {isMobileScreen ? (
          <div className={HeaderStyle.OverlayContent}>
            <div className={HeaderStyle.TopMobileCotent}>
              <div className={HeaderStyle.MobileMenuHeader}>
                <Link
                  to="#"
                  className={HeaderStyle.CloseBtn}
                  onClick={closeOverlay}>
                  <img src={BlackX} alt="Close" onClick={toggleMobileMenu} />
                </Link>
                <div className={"d-flex justify-content-center w-100"}>
                  <Link to="/" className={HeaderStyle.FooterLink}>
                    <img
                      // style={{ width: "100px", height: "28px" }}
                      src={MobileHeaderIcon}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard" className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img src={DashBoardIconSelected} alt="dashboard" />
                      </div>
                      <div style={{ alignSelf: "center" }}>DashBoard</div>
                    </div>
                  </Link>
                  <Link to="/my-orders" className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img src={MyTokenIconSelected} alt="my-orders" />
                      </div>
                      <div style={{ alignSelf: "center" }}>My Order</div>
                    </div>
                  </Link>
                  <Link
                    to="/buy-tokens"
                    className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img src={BuyTokenIcon} alt="buy-tokens" />
                      </div>
                      <div style={{ alignSelf: "center" }}>Buy Token</div>
                    </div>
                  </Link>
                  <Link
                    to="/redeem-list"
                    className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img src={RedeemTokenIconSelected} alt="redeem-list" />
                      </div>
                      <div style={{ alignSelf: "center" }}>Redeem Token</div>
                    </div>
                  </Link>
                  <Link
                    to="/my-profile"
                    className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <ImProfile color="#e8c056" size={18} />
                      </div>
                      <div style={{ alignSelf: "center" }}>My Profile</div>
                    </div>
                  </Link>
                  <Link
                    to="/search-distributor"
                    className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <ImProfile color="#e8c056" size={18} />
                      </div>
                      <div style={{ alignSelf: "center" }}>
                        Search Distributor
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="/meta-transaction"
                    className={HeaderStyle.MobileWhiteMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                      <img src={MyTokenIconSelected} alt="my-orders" />
                      </div>
                      <div style={{ alignSelf: "center" }}>
                        Meta Transaction
                      </div>
                    </div>
                  </Link>
                  <button
                    className={`${HeaderStyle.MobileWhiteMenu} `}
                    onClick={handleLogout}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <GrLogout color="#e8c056" size={20} />
                      <div style={{ alignSelf: "center" }}>Log Out</div>
                    </div>
                  </button>
                </>
              ) : (
                <Link to="/login" className={HeaderStyle.MobileWhiteMenu}>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <GrLogout color="#e8c056" size={20} />
                    </div>
                    <div style={{ alignSelf: "center" }}>Login</div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        ) : (
          <div className={HeaderStyle.OverlayContent}>
            <div className={HeaderStyle.TopMobileCotent}>
              <Link
                to="#"
                className={HeaderStyle.CloseBtn}
                onClick={closeOverlay}>
                <img src={CloseOrange} alt="Close" onClick={toggleMobileMenu} />
              </Link>
              <Link to="/" className={HeaderStyle.MobileMenu}>
                Home
              </Link>
              {/* <Link to="/why-graphite" className={HeaderStyle.MobileMenu}>
                Why Graphite?
              </Link> */}
              <Link to="/about" className={HeaderStyle.MobileMenu}>
                About
              </Link>
              <Link to="/contact-us" className={HeaderStyle.MobileMenu}>
                Contact
              </Link>
              <div className={HeaderStyle.MobileBtnContainer}>
                <button
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrange}`}
                  onClick={() => handleNav("/login")}>
                  Log in
                </button>
                <button
                  onClick={() => handleNav("/signup")}
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground}`}>
                  Sign up
                </button>
              </div>
            </div>
            <div className={FooterStyle.BottomMobileContent}>
              <Link to="/faq" className={FooterStyle.MobileFooterMenu}>
                FAQs
              </Link>
              <Link
                to="/privacy-policy"
                className={FooterStyle.MobileFooterMenu}>
                Privacy
              </Link>
            </div>
          </div>
        )}
      </div>
      <header
        className={
          title === "Purchase" || title === "Purchase Token"
            ? HeaderStyle.PurchaseHeaderContainer
            : forPage === "white" && isMobileScreen
            ? HeaderStyle.OthersHeaderContainer
            : HeaderStyle.HeaderContainer
        }
        // className={
        //   title === "Purchase" || title == "Purchase Token"
        //     ? HeaderStyle.PurchaseHeaderContainer
        //     : HeaderStyle.HeaderContainer
        // }
      >
        <div className={HeaderStyle.MobileMenuIcon}>
          <img
            src={
              forPage === "green"
                ? MenuWhite
                : forPage === "black"
                ? MenuYellow
                : MobileMenuIconWhite
            }
            alt="Logo"
            onClick={toggleMobileMenu}
          />
        </div>
        <div className={HeaderStyle.Logo}>
          <Link to="/" className={HeaderStyle.FooterLink}>
            <img
              src={
                forPage === "white" && isMobileScreen ? HeaderIcon : HeaderIcon
              }
              alt="Logo"
            />
          </Link>
        </div>
        <Link to="/login" className={HeaderStyle.MobileLoginMenu}>
          Log in
        </Link>
        <div className={HeaderStyle.DesktopMenuSection}>
          <Link
            to="/dashboard"
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/dashboard")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="Dashboard"
              iconPath="/dashboard"
              selectedIcon={DashBoardIconSelected}
              icon={DashBoardIcon}
              hover={hover}
            />
          </Link>
          <div
            onClick={() => handleNavigate("/my-orders")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/my-orders")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="My Tokens"
              iconPath="/my-orders"
              selectedIcon={MyTokenIconSelected}
              icon={MyTokenIcon}
              hover={hover}
            />
          </div>
          {/* <Dropdown className={HeaderStyle.DesktopMenu}>
            <Dropdown.Toggle className={HeaderStyle.dropMenu}>
              <MyCollectionIconComponent
                path={window.location.pathname}
                name="Collections"
              />
              <i
                className="fa fa-angle-down"
                style={
                  window.location.pathname === "/my-collection"
                    ? { color: "white" }
                    : {}
                }
                aria-hidden="true"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className={HeaderStyle.dropItems}
                onClick={() => handleNav("/my-collection")}>
                My Collections
              </Dropdown.Item>
              <Dropdown.Item
                className={HeaderStyle.dropItems}
                onClick={() => handleNav("/redemption-request")}>
                Redemption Request
              </Dropdown.Item>
              <Dropdown.Item
                className={HeaderStyle.dropItems}
                onClick={() => handleNav("/resell-request")}>
                Resell Request
              </Dropdown.Item>
              <Dropdown.Item
                className={HeaderStyle.dropItems}
                onClick={() => handleNav("/secondary-sale")}>
                Secondary Sale
              </Dropdown.Item>
              <Dropdown.Item
                className={HeaderStyle.dropItems}
                onClick={() => handleNav("/withdraw-request")}>
                Withdraw Request
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          <div
            onClick={() => handleNavigate("/buy-tokens")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/buy-tokens")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="Buy Token"
              iconPath="/buy-tokens"
              selectedIcon={BuyTokenIcon}
              icon={BuyTokenIconSelected}
              hover={hover}
            />
          </div>
          <div
            onClick={() => handleNavigate("/redeem-list")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/redeem-list")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="Redeem Tokens"
              iconPath="/redeem-list"
              selectedIcon={RedeemTokenIconSelected}
              icon={RedeemTokenIcon}
              hover={hover}
            />
          </div>
          <div
            onClick={() => handleNavigate("/search-distributor")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/search-distributor")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="Search Distributor"
              iconPath="/search-distributor"
              selectedIcon={SupportIcon}
              icon={SupportIcon}
              hover={hover}
            />
          </div>
          <div
            onClick={() => handleNavigate("/meta-transaction")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/meta-transaction")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="Meta Transaction"
              iconPath="/meta-transaction"
              selectedIcon={MyTokenIconSelected}
              icon={MyTokenIcon}
              hover={hover}
            />
          </div>

          {/* <Link
            to="/support"
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => sethover("/support")}
            onMouseLeave={() => sethover("")}>
            <CommonHeaderIcon
              path={window.location.pathname}
              name="Support"
              iconPath="/support"
              selectedIcon={SupportIcon}
              icon={SupportIcon}
              hover={hover}
            />
          </Link> */}
        </div>
        <div className={HeaderStyle.DesktopMenuSection}>
          {!isLoggedIn ? (
            <>
              <button
                className={ButtonStyle.PlainBtn}
                onClick={() => handleNav("/login")}>
                Log in
              </button>
              <button
                onClick={() => handleNav("/signup")}
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeBlackBackground}`}>
                Sign up
              </button>
            </>
          ) : (
            // <button
            //   className={ButtonStyle.PlainBtn}
            //   onClick={() => handleLogout()}>
            //   Logout
            // </button>
            <Dropdown>
              <Dropdown.Toggle className={HeaderStyle.Logout}>
                {userDetails?.user_image_url ? (
                  <Avatar
                    size="32"
                    round="20px"
                    src={userDetails?.user_image_url}
                  />
                ) : (
                  <Avatar
                    name={userDetails.name ? userDetails.name : ""}
                    size="34"
                    round={true}
                  />
                )}

                {/* <div className={HeaderStyle.dropdownIcon} /> */}
                {userDetails?.name?.length > 10
                  ? userDetails.name.slice(0, 10) + "..."
                  : userDetails.name}
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNav("/my-profile")}>
                  My profile
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </header>
    </>
  );
};
export default Header;
