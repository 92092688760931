// @ts-nocheck
import Styles from "./redeemToken.module.css";
import infoIcon from "../../Assets/Images/goldefi/infoIcon.svg";
import icon7 from "../../Assets/Images/goldefi/Icon7.svg";
import GoldBar from "../../Assets/Images/goldefi/goldIcon.svg";
import GoldefiCoin from "../../Assets/Images/goldefi/Goldefi_icons.svg";
import CirclePercentage from "../../Assets/Images/goldefi/circle-percentage.svg";
import InputField from "../Common/InputField";
import { useEffect, useState } from "react";
import ButtonStyles from "../../Components/Common/Button.module.css";
import {
  adminDetailsAPI,
  getRedeemProcessAPI,
  redeemRequestAPI,
  relayerCreateRedemption,
  updateTransactionHashAPI,
} from "../../service/api";
import notify from "../../utils/notify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CountrySelectField, StateSelectField } from "../Common/SelectField";
import { GoldDecimal, removeBlackListCountry } from "../../utils/common";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import metamask from "../../utils/metamask";
import RedeemRefundModal from "./RedeemRefundModal";
import moment from "moment";
import { setLoader } from "../../redux/actions/layout-action";
import {
  createRedemptionSign,
  getChains,
} from "../../service/thirdweb_service";

const RedeemTokenComponent = () => {
  const [tokenDetails, setTokenDetails] = useState({
    live_gold_rate: 0,
    platform_fee: 0,
  });

  const { redeemOrder } = useSelector((state) => state.redeemReducer);
  const [paymentMode, setPaymentMode] = useState("crypto_currency");
  const [contractAddress, setContractAddress] = useState("");
  const [redirectScreen, setRedirectScreen] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [redeemptionDetail, setRedeemptionDetail] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [tokenCount, setTokenCount] = useState();
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableInputField, setDisableInputField] = useState(false);
  const [userAddress, setUserAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    countryName: "",
    stateName: "",
  });
  const getCountryList = async () => {
    const res: any = await adminDetailsAPI();
    if (res.status) {
      const data = res.admin_details.countries_details;
      const blackListCountry = res.black_listed_countries;

      setCountryList(removeBlackListCountry(data, blackListCountry));
    } else {
      setCountryList([]);
    }
  };
  const changeStates = (country) => {
    const sta = countryList.find((countryObj) => {
      return countryObj.country_name === country;
    });
    setStateList(sta.states);
  };

  useEffect(() => {
    const loadMasterData = async () => {
      try {
        const result = await getRedeemProcessAPI();
        if (result.status) {
          setContractAddress(result.contract_address);
          setRedeemptionDetail(result.details[0]);
        }
      } catch (error) {
        console.error("An error occurred during Buy Token:", error);
      }
    };
    getCountryList();
    loadMasterData();
  }, []);
  const validateField = () => {
    if (tokenCount <= 0 || tokenCount == undefined) {
      notify.error("Please enter token value.");
      return true;
    }
    const coin = redeemCoin();
    if (coin > redeemptionDetail?.total_tokens_owned_by_user) {
      notify.error("Not enough tokens to redeem.");
      return true;
    }
    if (
      userAddress.street1.trim() === "" ||
      userAddress.city.trim() === "" ||
      userAddress.country <= 0 ||
      userAddress.zip.trim() === ""
    ) {
      notify.error("Please fill in all address Field.");
      return true;
    }

    return false;
  };
  const redeemToken = async () => {
    const valid = validateField();
    if (valid) {
      return;
    }
    setShow(false);
    const redeem = JSON.parse(redeemptionDetail.conversion).toFixed(2);
    dispatch(setLoader(true));
    const data = {
      quantity: parseFloat(tokenCount * redeem),
      wallet_address: userDetails.wallet_address
        ? userDetails.wallet_address
        : "",
      street: userAddress.street1,
      street2: userAddress.street2,
      city: userAddress.city,
      state_id: userAddress.state,
      zip: userAddress.zip,
      country_id: userAddress.country,
    };
    const res = await redeemRequestAPI(data);

    if (res.status) {
      const { request, ...rest } = res.toSign.payload;
      const updatedPayload = { ...rest, message: request };
      const resp = await createRedemptionSign(
        userDetails.wallet_address,
        updatedPayload,
        getChains("polygon")
      );
      if (resp.result) {
        const info = {
          redemptionID: res.redemption_id,
          amount: parseInt(tokenCount * redeem),
          user_wallet: userDetails.wallet_address,
          signature: resp.result,
          deadline: updatedPayload.message.deadline,
        };

        const reply = await relayerCreateRedemption(info);
        if (reply.status === 1) {
          const date = Date.now();
          const body = {
            redemption_ref: res.redemption_ref,
            active: "create",
            transaction_hash: reply.hash,
            time_stamp: date,
          };
          const response = await updateTransactionHashAPI(body);
          if (response.status) {
            notify.success(res.message);
            navigate("/redeem-list");
          }
        }
      }
    }
    dispatch(setLoader(false));
  };
  const handleOpenModal = () => {
    const valid = validateField();
    if (valid) {
      return;
    }
    setShow(true);
  };

  const handleCopyAddress = (e) => {
    if (e.target.checked) {
      setDisableInputField(true);
      const country = countryList.find(
        (item) => item.country_name === userDetails.country
      );
      setStateList(country.states);
      const state = country.states.find(
        (item) => item.name === userDetails.state
      );
      setUserAddress({
        street1: userDetails.address,
        street2: userDetails.address2,
        city: userDetails.city,
        state: state?.id ? state.id : "",
        country: country.country_id,
        zip: userDetails.zip,
        countryName: userDetails.country,
        stateName: userDetails.state,
      });
    } else {
      setDisableInputField(false);
      setUserAddress({
        street1: "",
        street2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        countryName: "",
        stateName: "",
      });
    }
  };
  const getFees = () => {
    const fees =
      redeemptionDetail.active == "Percentage"
        ? redeemptionDetail.perc_fee
        : redeemptionDetail.active == "Fixed"
        ? redeemptionDetail.fixed
        : redeemptionDetail.minimum_fee;
    return fees;
  };

  const redeemCoin = () => {
    const redeem = JSON.parse(redeemptionDetail.conversion).toFixed(2);
    const fees = getFees();
    if (redeemptionDetail.active == "Fixed") {
      //ReFundable in percent
      // const num =
      //   JSON.parse(tokenCount) *
      //   JSON.parse(redeemptionDetail.conversion).toFixed(2) *
      //   (JSON.parse(redeemptionDetail.redemption_processing_fee).toFixed(2) /
      //     100);
      return (
        tokenCount * redeem +
        fees +
        redeemptionDetail.redemption_processing_fee
      ).toFixed(2);
    } else {
      const val = (tokenCount * redeem).toFixed(2);
      //ReFundable in percent
      // const platformfee =
      //   val * (redeemptionDetail.redemption_processing_fee / 100);
      const platformfee = redeemptionDetail.redemption_processing_fee;
      const percent = val * (fees / 100);
      return (tokenCount * redeem + percent + platformfee).toFixed(2);
    }
  };
  // console.log("redeemptionDetail", redeemptionDetail);
  return (
    <div className={Styles.buyTokenContainer}>
      {redirectScreen === "" && (
        <>
          <div
            className={
              Styles.buyToken + " " + (showLoader ? Styles.opaque : "")
            }>
            <div className={Styles.buyTokenText}>Redeem Tokens</div>

            <div className={Styles.totalCard}>
              <div className={Styles.totalCol1}>
                Total Available Gold
                <div className={Styles.unitCount}>
                  {redeemptionDetail.total_gold
                    ? redeemptionDetail?.total_gold.toFixed(GoldDecimal)
                    : "0"}
                  <span className={Styles.unit}>
                    Troy oz
                    {/* <img src={infoIcon} alt="info" /> */}
                  </span>
                </div>
              </div>
              <div className={Styles.totalCol2}>
                Total Token
                <div className={Styles.unitCount}>
                  {" "}
                  {redeemptionDetail.total_tokens_owned_by_user
                    ? redeemptionDetail?.total_tokens_owned_by_user.toFixed(2)
                    : "0"}
                </div>
              </div>
            </div>
          </div>

          <div className={"d-grid gap-5 " + (showLoader ? Styles.opaque : "")}>
            <div className="d-grid gap-4 gap-md-5">
              <div
                className={
                  Styles.textContainer +
                  " " +
                  (tokenCount > 0 ? Styles.checked : "")
                }>
                {tokenCount > 0 && (
                  <img className={Styles.tickImg} src={icon7} alt="" />
                )}
                <div>
                  <div className={Styles.headText}>Let’s get started</div>
                  <div className={Styles.subText}>
                    How many tokens would you like to redeem from your account?
                  </div>
                </div>
              </div>
              <div
                className={
                  Styles.btnContainer + " " + Styles.mobilebtnContainer
                }>
                <div className={Styles.inputToken}>
                  <InputField
                    // label="Email"
                    value={String(tokenCount)}
                    type="number"
                    placeholder="Gold in (Troy oz)"
                    onChange={(e) => {
                      if (
                        Number(e.target.value) >= 0 &&
                        !e.target.value.includes(".") &&
                        e.target.value.length < 4
                      ) {
                        setTokenCount(Number(e.target.value));
                      }
                    }}
                    // height="50px"
                    iconPath={GoldBar}
                    name="token"
                    maxLength={5}
                    dark={true}
                    showIcon={true}
                  />
                </div>
                <div className={Styles.inputToken}>
                  <div className={Styles.percentageContainer}>
                    {redeemptionDetail.active == "Fixed" ? (
                      <img src={GoldefiCoin} alt="Percentage" />
                    ) : (
                      <img src={CirclePercentage} alt="Percentage" />
                    )}
                    <div className={Styles.percentageNumber}>
                      {redeemptionDetail.active == "Percentage"
                        ? redeemptionDetail.perc_fee
                        : redeemptionDetail.active == "Fixed"
                        ? redeemptionDetail.fixed
                        : redeemptionDetail.minimum_fee}{" "}
                      <span className={Styles.percentageText}>
                        Platform Fee
                      </span>
                    </div>
                  </div>
                </div>
                <div className={Styles.inputToken}>
                  <div className={Styles.percentageContainer}>
                    <img src={GoldefiCoin} alt="Percentage" />
                    <div className={Styles.percentageNumber}>
                      {redeemptionDetail.redemption_processing_fee}{" "}
                      <span className={Styles.percentageText}>
                        Non-Refundable Fee
                      </span>
                    </div>
                  </div>
                </div>
                <div className={Styles.inputToken}>
                  <div className={Styles.percentageContainer}>
                    <img src={GoldefiCoin} alt="Percentage" />
                    <div className={Styles.percentageNumber}>
                      {!tokenCount ? 0 : redeemCoin()}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "d-grid gap-4 gap-md-5 " +
                (paymentMode === "" ? Styles.shadow : "")
              }>
              <div
                className={
                  Styles.textContainer +
                  " " +
                  (tokenCount > 0 ? Styles.checked : "")
                }>
                {tokenCount > 0 && (
                  <img className={Styles.tickImg} src={icon7} alt="" />
                )}
                <div>
                  <div className={Styles.headText}>Next</div>
                  <div className={Styles.subText}>
                    Where do you want to deliver the gold?
                  </div>
                </div>
              </div>
              <div
                className={
                  Styles.btnContainer + " " + Styles.mobilebtnContainer
                }>
                <div className="d-flex gap-3 align-items-center">
                  <input
                    // checked={mineTypeWallet}
                    onChange={handleCopyAddress}
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <span className={Styles.subText}>
                    Copy the address from My Profile.
                  </span>
                </div>
              </div>
              <div
                className={
                  Styles.btnContainer + " " + Styles.mobilebtnContainer
                }>
                <div className={Styles.inputToken}>
                  <InputField
                    value={userAddress.street1}
                    type="text"
                    maxLength={50}
                    placeholder="Street"
                    name="street1"
                    disabled={disableInputField && userDetails.address !== ""}
                    dark={true}
                    onChange={(e) => {
                      const isValid = /^([A-Za-z0-9_\- ]+)?$/.test(
                        e.target.value
                      );
                      if (isValid) {
                        setUserAddress({
                          ...userAddress,
                          street1: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <div className={Styles.inputToken}>
                  <InputField
                    value={userAddress.street2}
                    type="text"
                    maxLength={50}
                    placeholder="(Optional) Street"
                    name="street2"
                    dark={true}
                    disabled={disableInputField && userDetails.address2 !== ""}
                    onChange={(e) => {
                      const isValid = /^([A-Za-z0-9_\- ]+)?$/.test(
                        e.target.value
                      );
                      if (isValid) {
                        setUserAddress({
                          ...userAddress,
                          street2: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <div className={Styles.inputToken}>
                  <InputField
                    value={userAddress.city}
                    type="text"
                    maxLength={50}
                    placeholder="City"
                    name="city"
                    disabled={disableInputField && userDetails.city !== ""}
                    dark={true}
                    onChange={(e) => {
                      const isValid = /^([A-Za-z0-9_\- ]+)?$/.test(
                        e.target.value
                      );
                      if (isValid) {
                        setUserAddress({
                          ...userAddress,
                          city: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  Styles.btnContainer + " " + Styles.mobilebtnContainer
                }>
                <div className={Styles.inputToken}>
                  <CountrySelectField
                    value={userAddress.countryName}
                    options={countryList}
                    type="text"
                    placeholder="Select Country"
                    name="Country"
                    dark={true}
                    disabled={disableInputField && userDetails.country !== ""}
                    onChange={(e) => {
                      const country = countryList.find(
                        (item) => item.country_name === e.target.value
                      );
                      setUserAddress({
                        ...userAddress,
                        country: country.country_id,
                        countryName: e.target.value,
                      });
                      changeStates(e.target.value);
                    }}
                  />
                </div>
                <div className={Styles.inputToken}>
                  <StateSelectField
                    value={userAddress.stateName}
                    options={stateList}
                    type="text"
                    placeholder="State"
                    name="State"
                    disabled={disableInputField && userDetails.state !== ""}
                    dark={true}
                    onChange={(e) => {
                      const state = stateList.find(
                        (item) => item.name === e.target.value
                      );
                      setUserAddress({
                        ...userAddress,
                        state: state.id,
                        stateName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className={Styles.inputToken}>
                  <InputField
                    value={userAddress.zip}
                    type="text"
                    placeholder="Zip Code"
                    name="zip"
                    disabled={disableInputField && userDetails.zip !== ""}
                    maxLength={10}
                    dark={true}
                    onChange={(e) => {
                      const isValid = /^[0-9]+$/.test(e.target.value);
                      if (isValid) {
                        setUserAddress({
                          ...userAddress,
                          zip: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className={
                "d-grid gap-5 " + (paymentMode === "" ? Styles.shadow : "")
              }>
              <div className={Styles.loginBtn}>
                <button
                  type="submit"
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
                  onClick={handleOpenModal}>
                  <span>Redeem</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {show && (
        <RedeemRefundModal
          show={show}
          onHide={() => setShow(false)}
          redeemToken={redeemToken}
          tokenCount={tokenCount}
          redeemptionDetail={redeemptionDetail}
          getFees={getFees}
          totalAmount={redeemCoin}
        />
      )}
    </div>
  );
};
export default RedeemTokenComponent;
